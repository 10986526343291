import React from 'react'

interface WysiwygData {
  children?: any
  className?: string
}

const Wysiwyg = ({ children = '', className = '' }: WysiwygData) => {
  return (
    <div className={`wysiwyg flex items-center justify-center`}>
      <div className={`${className}`}>
        <div dangerouslySetInnerHTML={{ __html: children }} />
      </div>
    </div>
  )
}
export default Wysiwyg
