import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import People from '@/components/People'
import Wysiwyg from '@/components/Wysiwyg'
import Image from '@/components/Image'
import MainHeading from '@/components/MainHeading'
import Seo from '@/components/Seo'
import { seotype } from '@/types.d'
interface ContactData {
  data: {
    form: any
    page: {
      title?: string
      featuredImage?: any
      gwContent?: string
      gwMapImage: string
      people: any
      uri: string
      seo: seotype
      language: {
        slug: string
      }
      translations: []
    }
    mainMenu: any
    langData: any
    wp: any
  }
}

const ContactTemplate = ({ data }: ContactData) => {
  const { page } = data
  const { form } = data
  const { people } = page
  const { mainMenu } = data
  const { wp } = data

  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }

  return (
    <Layout featuredImage={page.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          lang={page?.language?.slug}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container>
        <MainHeading>{page.title}</MainHeading>
        <div className="sm:grid-cols-12 grid gap-4 mt-20">
          <div className="sm:col-span-5">
            <Wysiwyg className="w-full">{page.gwContent}</Wysiwyg>
          </div>
          <div className="sm:col-span-7">{page.gwMapImage && <Image alt="Map" image={page.gwMapImage} />}</div>
        </div>
        {people && (
          <div className="my-20">
            <People
              className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-4 lg:gap-x-16 sm:gap-y-4 gap-y-10"
              people={people}
            />
          </div>
        )}
      </Container>
    </Layout>
  )
}

export const ContactPageQuery = graphql`
  query ContactPageById($id: String, $menu: [WpMenuLocationEnum], $translang: String, $formNum: Int) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      gwMapImage
      gwContent
      ...people
      ...featuredHeroImage
      ...langdata
      ...seo
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default ContactTemplate
